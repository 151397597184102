import numeral from 'numeral';
import { proxied } from 'radash';
import { CurrencyEnum, DefaultCurrencyFormat } from './constant/etc';

const currencyConfig = proxied((prop: CurrencyEnum) => {
  switch (prop) {
    case CurrencyEnum.VND:
      return { format: '0,0.000', replaceComma: true };
    default:
      return { format: DefaultCurrencyFormat };
  }
});

export const currencyFormat = (
  balance: number | undefined,
  currency: CurrencyEnum = CurrencyEnum.THB,
  format?: string,
): string => {
  if (balance === undefined) {
    return '-';
  }

  const { format: defaultFormat, replaceComma } = currencyConfig[currency];

  const selectedFormat = format || defaultFormat;
  let formattedBalance = numeral(balance).format(selectedFormat);

  if (replaceComma) {
    formattedBalance = formattedBalance.replace(/,/g, '.');
  }

  return formattedBalance;
};
